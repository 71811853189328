.react-page-0{
    .footnote-container{
        padding-top: 1rem;
    }
    .overflow {
        overflow: hidden;
    }
    .heading {
        font-size: 3rem;
        font-weight: 800;
        margin-top: 1%;
        text-transform: uppercase;
    }
    @media (min-width: 1366px) {
        .heading {
            font-size: 3.6rem;
        }
        .homepage-img{
            width: 80%;
        }
    }
    .heading sup {
        font-size: 1.6rem;
    }
    @media (min-width: 1366px) {
        .heading sup {
            font-size: 1.9rem;
        }
    }
    .heading-description {
        padding-top: 2rem;
        font-size: 1.1rem;
        font-weight: 600;
    }
    @media (min-width: 1366px) {
        .heading-description {
            font-size: 1.35rem;
        }
    }
    .heading-description sup {
        font-size: 0.6rem;
    }
    @media (min-width: 1366px) {
        .heading-description sup {
            font-size: 0.8rem;
        }
    }
    .subheading {
        font-size: 1.6rem;
        font-weight: 600;
    }
    @media (min-width: 1366px) {
        .subheading {
            font-size: 1.9rem;
        }
    }
    .homepage-img {
        // margin: -7%;
        position: relative;
    }

}

