.react-page-14{
  .graph-bar-1{
    --bar-height: 98%;
    --bar-duration: 1.5s;
    --bar-initial:54%;
  }
  .graph-bar-2{
    --bar-height: 100%;
    --bar-duration:1.52s;
    --bar-initial:54%;
  }
  .graph-bar-3{
    --bar-height: 61%;
  }
  .graph {
      position: relative;
  }
  .graph-container {
      display: flex;
      height: 100%;
      justify-content: space-between;
      left: 0;
      padding: .7% 45% 15% 20%;
      position: absolute;
      top: 10px;
      width: 100%;
  }
  @media (min-width: 1366px) {
      .graph-container {
          top: 13px;
      }
  }
  .graph-container.inverted {
      align-items: flex-end;
      left: 0;
      top: 8px;
  }
  @media (min-width: 1366px) {
      .graph-container.inverted {
          top: 10px;
      }
  }
  .graph-bar {
      animation-duration: var(--bar-duration);
      animation-name: grow;
      animation-timing-function: var(--bar-function);
      border-radius: 0 0 8px 8px;
      color: #fff;
      font-weight: 600;
      height: var(--bar-height);
      padding: 7px 0 0;
      text-align: center;
      width: 45px;
  }
  @media (min-width: 1366px) {
      .graph-bar {
          width: 60px;
      }
  }
  .graph-bar.inverted {
      border-radius: 8px 8px 0 0;
  }
  .graph-bar > div {
      margin: 5px 0;
  }
  @keyframes grow {
      0% {
          height: var(--bar-initial, var(--bar-height));
      }
      to {
          height: var(--bar-height);
      }
  }
  .title--margin {
      margin-right: 24%;
  }
  .subtitle--margin {
      margin: 1% 0;
  }
  .chart-image {
      width: auto;
  }
  .chart-note--size {
      margin-left: 8%;
      width: 33%;
  }
  .chart-content {
      margin-bottom: 2rem;
  }
  .chart-info--size {
      margin-top: 2%;
  }
  .list--size {
      margin-right: 26%;
  }
  .graph {
      width: 49%;
  }

}