.react-page-17{

  .sitemap-item, .sitemap-item-child{
    cursor: pointer;
    img{
      border: 1px solid #41748d;
    }
  }

  .sitemap-main-wrapper{
    display: flex;
    position: relative;
    justify-content: center;
  }
  h3{
    color: #41748d;
    font-weight: bold;
    font-family: Proxima Nova;
  }
  .sitemap-item{
      position: relative;
      padding:1rem;
      @media (min-width: 1366px) {
        width: 250px
      }
      h3{
        text-align: center;
        margin-bottom: .5rem;
        font-size: .5rem;
        @media (min-width: 1366px) {
          font-size: 1rem;
        }
      }
  }

  .sitemap-item-child-wrapper-1{
    position: absolute;
    width: 100%;
    left: -100%;
    top: 125%;
    background: rgb(65 116 141 / 30%);
    border-radius: 20px;
  }
  .sitemap-item-child-wrapper-2{
    position: absolute;
    width: 100%;
    left: 10%;
    top: 125%;
    background: rgb(126 188 198 / 30%);
    border-radius: 20px;
  }
  .sitemap-item-child-wrapper-3{
    position: absolute;
    width: 100%;
    left: 120%;
    top: 125%;
    background: rgb(192 44 87 / 30%);
    border-radius: 20px;

  }
  .sitemap-item-child-wrapper-4{
    position: absolute;
    width: 100%;
    left: 50%;
    top: 125%;
    background: rgb(202 202 202 / 30%);
    border-radius: 20px;
  }
  .sitemap-item-child-wrapper-5{
    position: absolute;
    width: 100%;
    left: 60%;
    top: 125%;
    background: rgb(202 202 202 / 30%);
    border-radius: 20px;
  }

  .sitemap-item-child{
    width: 100%;
    padding: 1rem;
  }

  .sitemap-child-wrapper{
    display: flex;
    justify-content: center;
    // position: absolute;
    padding-top: 3rem;
    width: 100%;
  }
  .sitemap-child-wrapper-column{
    // padding: 1rem;
  }
  .dashed-line-1{
    border-right: 2px dashed #41748d;
    width: 1px;
    height: 15%;
    position: absolute;
    bottom: -6%;
    left: 50%;
  }
  .dashed-line-2{
    border-bottom: 2px dashed #41748d;
    width: 220%;
    height: 2px;
    position: absolute;
    bottom: -6%;
    left: -50%;
  }
  .dashed-line-3{
    border-right: 2px dashed #41748d;
    width: 1px;
    height: 15%;
    position: absolute;
    bottom: -22%;
    left: -50%;
  }
  .dashed-line-4{
    border-right: 2px dashed #41748d;
    width: 1px;
    height: 15%;
    position: absolute;
    bottom: -22%;
    left: 59%;
  }
  .dashed-line-5{
    border-right: 2px dashed #41748d;
    width: 1px;
    height: 15%;
    position: absolute;
    bottom: -20%;
    left: 170%;
  }
  .dashed-line-6{
    border-right: 2px dashed #41748d;
    width: 1px;
    height: 15%;
    position: absolute;
    bottom: -6%;
    left: 51%;
  }
  .dashed-line-7{
    border-bottom: 2px dashed #41748d;
    width: 46%;
    height: 2px;
    position: absolute;
    bottom: -7%;
    left: 51%;
  }
  .dashed-line-8{
    border-right: 2px dashed #41748d;
    width: 1px;
    height: 15%;
    position: absolute;
    bottom: -21%;
    left: 97%;
  }
  .dashed-line-9{
    border-right: 2px dashed #41748d;
    width: 1px;
    height: 15%;
    position: absolute;
    bottom: -6%;
    left: 51%;
  }
  .dashed-line-10{
    border-bottom: 2px dashed #41748d;
    width: 55%;
    height: 2px;
    position: absolute;
    bottom: -7%;
    left: 51%;
  }
  .dashed-line-11{
    border-right: 2px dashed #41748d;
    width: 1px;
    height: 15%;
    position: absolute;
    bottom: -21%;
    left: 106%;
  }

}

.extra-infos{
  .info-card{
    color: #000;
    max-width: 100%;
    width: 1200px;
    display: block;
    margin: 0 auto;
    padding-top: 5rem;
    padding-right: 5rem;
    padding-left: 5rem;
  }
}
.fimea-triangle{
  position: relative;
  padding-left: 2rem;
}



.fimea-triangle:after {
    left: 0;
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    border-top: 23px solid #000;
}