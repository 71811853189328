.react-page-9{
  .graph-bar-1{
    --bar-height: 52%; --bar-initial:26%; --bar-duration: 1.5s;
  }
  .graph-bar-2{
    --bar-height: 62%; --bar-initial:26%; --bar-duration: 1.5s;
  }
  .graph-bar-3{
    --bar-height: 26%;
  }
  .graph-bar-4{
    --bar-height: 45%; --bar-initial:25%; --bar-duration: 1.5s;
  }
  .graph-bar-5{
    --bar-height: 55%;--bar-initial:25%;  --bar-duration: 1.5s;
  }
  .graph-bar-6{
--bar-height: 25%;
  }


  .graph {
      position: relative;
  }
  .graph-container {
      display: flex;
      height: 100%;
      justify-content: space-between;
      left: 0;
      padding: 0 45% 15% 20%;
      position: absolute;
      top: 10px;
      width: 100%;
  }
  @media (min-width: 1366px) {
      .graph-container {
          top: 13px;
      }
  }
  .graph-container.inverted {
      align-items: flex-end;
      left: 0;
      top: 8px;
  }
  @media (min-width: 1366px) {
      .graph-container.inverted {
          top: 11px;
      }
  }
  .graph-bar {
      animation-duration: var(--bar-duration);
      animation-name: grow;
      animation-timing-function: var(--bar-function);
      border-radius: 0 0 8px 8px;
      color: #fff;
      font-weight: 600;
      height: var(--bar-height);
      padding: 7px 0 0;
      text-align: center;
      width: 45px;
  }
  @media (min-width: 1366px) {
      .graph-bar {
          width: 60px;
      }
  }
  .graph-bar.inverted {
      border-radius: 8px 8px 0 0;
  }
  .graph-bar > div {
      margin: 5px 0;
  }
  @keyframes grow {
      0% {
          height: var(--bar-initial, var(--bar-height));
      }
      to {
          height: var(--bar-height);
      }
  }
  .title--margin {
      margin-right: 16%;
  }
  .chart-container {
      margin-bottom: 2%;
  }
  .chart-image {
      width: auto;
  }
  .chart-info--margin {
      margin-bottom: 2%;
      margin-top: -2%;
  }

  .chart-note{
    padding: 5% 0;
  }

  .chart-note--size {
      // margin-bottom: 3%;
      margin-left: 5%;
      // margin-top: 3%;
      // padding-bottom: 2%;
      width: 31%;
  }
  @media (min-width: 1366px) {
      .chart-note--size {
          width: 29%;
      }
  }
  .list--size {
      margin-right: 20%;
  }
  .graph {
      width: 80%;
      @media (min-width: 1366px) {
        width: 49%;
      }
  }
  .graph-container {
    padding: 11% 11% 8.5% 17%;
  }
  .bar-spacer {
      width: 30px;
  }

}