.react-page-4{

    .extra-red-line{
        position: absolute;
        height: 2px;
        background: #c02c57;
        width: 200px;
        top: 48%;
        transform: translateY(-50%);
        z-index: -1;
    }
    .title--margin {
        margin-right: 18%;
    }
    .list__title {
        font-weight: 800;
        margin: 2% 0;
        color: #41748d;
        font-size: 1.6rem;
    }
    .list__title sup {
        font-size: 0.6rem;
    }
    @media (min-width: 1366px) {
        .list__title sup {
            font-size: 0.8rem;
        }
    }
    .list--size {
        margin-left: 2%;
        margin-right: 40%;
    }
    .iv {
        margin: 2rem 0 2rem;
        position: relative;
        @media (min-width: 1366px) {
            margin: 6rem 0 2rem;
        }
    }
    .iv__gifs {
        width: 40%;
        @media (min-width: 1366px) {
            width: 30%;
        }
    }
    .iv-red-line{
        position: absolute;
        top: 49%;
        width: 30%;
        height: 1%;
        background: #c02c57;
        z-index: -1;
        @media (min-width: 1366px) {
            height: 2%;
            transform: translateY(-50%);
        }
    }

    .iv__img {
        bottom: 50%;
        position: absolute;
        width: 55%;
        left: 17%;
        z-index: -1;
        @media (min-width: 1366px) {
            width: 40%;
            left: 16%;
        }
    }
    .list__item{
        ul{
            list-style-type: circle;
        }
    }
}