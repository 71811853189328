.react-page-8{

  .graph-bar-1{
    --bar-height: 82%; --bar-initial: 56%; --bar-duration: 1.5s;
  }
  .graph-bar-2{
    --bar-height: 87%; --bar-initial: 56%; --bar-duration: 1.5s; --bar-function: cubic-bezier(0.25, 1.15, 0.85, 0.97);
  }
  .graph-bar-3{
    --bar-height: 56%;
  }
  .graph-bar-4{
    --bar-height: 57%;
  }
  .graph-bar-5{
    --bar-height: 59%;
  }
  .graph-bar-6{
    --bar-height: 34%;
  }
  .graph-bar-7{
    --bar-height: 58%;
  }
  .graph-bar-8{
    --bar-height: 54%;
  }
  .graph-bar-9{
    --bar-height: 26%;
  }
  .graph-bar-10{
    --bar-height: 39%;
  }
  .graph-bar-11{
    --bar-height: 39%; --bar-duration: 1.5s;
  }
  .graph-bar-12{
    --bar-height: 22%; --bar-duration: 1.5s;
  }

  .graph {
      position: relative;
  }
  .graph-container {
      display: flex;
      height: 100%;
      justify-content: space-between;
      left: 0;
      position: absolute;
      top: 10px;
      width: 100%;
  }
  @media (min-width: 1366px) {
      .graph-container {
          top: 13px;
      }
  }
  .graph-container.inverted {
      align-items: flex-end;
      left: 0;
      top: 8px;
  }
  @media (min-width: 1366px) {
      .graph-container.inverted {
          top: 10px;
      }
  }
  .graph-bar {
      animation-duration: var(--bar-duration);
      animation-name: grow;
      animation-timing-function: var(--bar-function);
      border-radius: 0 0 8px 8px;
      color: #fff;
      font-weight: 600;
      height: var(--bar-height);
      padding: 7px 0 0;
      text-align: center;
      width: 45px;
  }
  @media (min-width: 1366px) {
      .graph-bar {
          width: 60px;
      }
  }
  .graph-bar.inverted {
      border-radius: 8px 8px 0 0;
  }
  .graph-bar > div {
      margin: 5px 0;
  }
  @keyframes grow {
      0% {
          height: var(--bar-initial, var(--bar-height));
      }
      to {
          height: var(--bar-height);
      }
  }
  .chart-image {
      // margin-bottom: 15%;
      width: auto;
  }
  .chart-info--margin {
      // margin-bottom: 12%;
      // margin-top: -8%;
  }
  .graph {
      font-size: 0.8rem;
      width: 100%;
      @media (min-width: 1366px) {
          width: 65%;
      }
  }
  @media (min-width: 1366px) {
      .graph {
          font-size: 1rem;
      }
  }
  .graph-container.inverted {
      top: 5px;
  }
  @media (min-width: 1366px) {
      .graph-container.inverted {
          top: 6px;
      }
  }
  .graph-container {
    padding: 0 15% 9.5% 9%;
  }
  .bar-spacer {
      margin-right: 15px;
  }

}