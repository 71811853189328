.react-page-3{

    .graph-bar-1{
        --bar-height: 67%;
        --bar-duration: 1.5s;
        --bar-initial:20%;
    }
    .graph-bar-2{
        --bar-height: 74%; --bar-duration: 1.5s; --bar-initial:20%;
    }
    .graph-bar-3{
        --bar-height: 20%;
    }
    .graph-bar-4{
        --bar-height: 85%; --bar-duration: 1.5s; --bar-initial:31%;
    }
    .graph-bar-5{
        --bar-height: 85%; --bar-duration: 1.5s; --bar-initial:31%;
    }
    .graph-bar-6{
        --bar-height: 31%;
    }

    .graph {
        width: 810px;
        position: relative;
    }
    .graph-container {
        display: flex;
        height: 100%;
        justify-content: space-between;
        left: 0;
        padding: 0 45% 15% 20%;
        position: absolute;
        top: 10px;
        width: 100%;
    }
    @media (min-width: 1366px) {
        .graph-container {
            top: 13px;
        }
    }
    .graph-container.inverted {
        align-items: flex-end;
        left: 0;
        top: 8px;
    }
    @media (min-width: 1366px) {
        .graph-container.inverted {
            top: 10px;
        }
    }

    .graph-bar {
        animation-duration: var(--bar-duration);
        animation-name: grow;
        animation-timing-function: var(--bar-function);
        border-radius: 0 0 8px 8px;
        color: #fff;
        font-weight: 600;
        height: var(--bar-height);
        padding: 7px 0 0;
        text-align: center;
        width: 45px;
    }
    @media (min-width: 1366px) {
        .graph-bar {
            width: 60px;
        }
    }
    .graph-bar.inverted {
        border-radius: 8px 8px 0 0;
    }
    .graph-bar > div {
        margin: 5px 0;
    }
    @keyframes grow {
        0% {
            height: var(--bar-initial, var(--bar-height));
        }
        to {
            height: var(--bar-height);
        }
    }
    @keyframes sustained-head {
        0% {
            height: 10.5rem;
        }
        to {
            height: 12.2rem;
        }
    }
    @keyframes sustained-head--medium {
        0% {
            height: 14.5rem;
        }
        to {
            height: 16.5rem;
        }
    }
    @keyframes fast-arrow {
        0% {
            left: 30%;
            opacity: 0;
        }
        to {
            left: 50%;
            opacity: 1;
        }
    }
    @keyframes efficacious-rectangle {
        0% {
            top: 36%;
        }
        to {
            top: 39%;
        }
    }
    .title--margin {
        margin-right: 15%;
    }
    .grid__column--margin {
        margin: 0 1%;
    }
    .core-flow__header {
        font-size: 1.1rem;
        font-weight: 800;
        text-transform: uppercase;
    }
    @media (min-width: 1366px) {
        .core-flow__header {
            font-size: 1.5rem;
        }
    }
    .core-flow__image {
        align-items: flex-end;
        display: flex;
        height: 13rem;
        margin: 6% 0;
        position: relative;
    }
    @media (min-width: 1366px) {
        .core-flow__image {
            height: 18rem;
        }
    }
    .core-flow__background {
        max-height: 13rem;
        width: auto;
    }
    @media (min-width: 1366px) {
        .core-flow__background {
            max-height: 18rem;
        }
    }
    .core-flow__efficacious-rectangle {
        position: absolute;
        top: 36%;
    }
    .core-flow__efficacious-rectangle.animation {
        animation: efficacious-rectangle 0.5s linear forwards;
        animation-delay: 1.5s;
    }
    .core-flow__fast-arrow {
        height: 3.8rem;
        left: 30%;
        opacity: 0;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: auto;
    }
    .core-flow__fast-arrow.animation {
        animation: fast-arrow 0.8s linear forwards;
        animation-delay: 2s;
    }
    .core-flow__sustained-head {
        bottom: 0.6707rem;
        height: 10.5rem;
        left: 47%;
        position: absolute;
        transform: translateX(-50%);
        width: auto;
    }
    @media (min-width: 1366px) {
        .core-flow__sustained-head {
            bottom: 0.85rem;
            height: 14.5rem;
        }
    }
    .core-flow__sustained-head.animation {
        animation: sustained-head 0.8s linear forwards;
        animation-delay: 2.8s;
    }
    @media (min-width: 1366px) {
        .core-flow__sustained-head.animation {
            animation: sustained-head--medium 0.8s linear forwards;
            animation-delay: 2.8s;
        }
    }
    .core-flow__text {
        color: #41748d;
        font-size: 1rem;
        font-weight: 400;
        text-align: center;
    }
    @media (min-width: 1366px) {
        .core-flow__text {
            font-size: 1.2rem;
        }
    }
    .core-flow__text sup {
        font-size: 0.6rem;
    }
    @media (min-width: 1366px) {
        .core-flow__text sup {
            font-size: 0.8rem;
        }
    }
    .core-flow__emphasis {
        display: block;
        font-size: 1.8rem;
        font-weight: 800;
        margin: 1% 0;
    }
    @media (min-width: 1366px) {
        .core-flow__emphasis {
            font-size: 2.2rem;
        }
    }
    .core-flow__emphasis sup,
    .moh-btn {
        font-size: 1rem;
    }
    .moh-btn {
        background: none;
        border: 1px solid #d9d9d9;
        border-radius: 30rem;
        box-shadow: 0.2rem 0.3rem 0.6rem #d9d9d9;
        color: #666;
        cursor: pointer;
        font-family: Proxima Nova;
        font-weight: 600;
        margin-bottom: 2%;
        outline: none;
        padding: 1% 3%;
    }
    @media (min-width: 1366px) {
        .moh-btn {
            font-size: 1.1rem;
        }
    }
    .pop-up-btn {
        background: none;
        border: 0;
        bottom: -5.5%;
        cursor: pointer;
        left: 54%;
        position: absolute;
        width: 30%;
    }
    .modal__card--moh {
        height: calc(100vh - 25%);
        left: 6%;
        padding: 1% 2%;
        top: 20%;
        width: calc(100vw - 40%);
    }
    .footnote-list {
        margin-top: 2rem;
    }
    .footnote-list__item {
        margin-bottom: 0.1rem;
    }
    .footnote-list--modal {
        margin-top: 1rem;
    }
    .graph-container {
        padding: 6% 24% 6% 22%;
    }
    .graph-container.inverted {
        top: 10px;
    }
    @media (min-width: 1366px) {
        .graph-container.inverted {
            top: 13px;
        }
    }
    .graph-bar {
        display: flex;
        flex-direction: column;
        font-size: 0.8rem;
        justify-content: space-between;
        padding: 15px 1px 1px;
        width: 40px;
    }
    .graph-bar .bottom-index {
        font-size: 0.6rem;
        font-weight: 100;
        margin: 2px 0;
    }
    @media (min-width: 1366px) {
        .graph-bar .bottom-index {
            font-size: 0.8rem;
            margin: 5px 0;
        }
        .graph-bar {
            font-size: 1rem;
            width: 50px;
        }
    }
    .bar-spacer {
        width: 20px;
    }

    #moh-modal{
        &.visible{
            opacity: 1;
        }
    }

}