.react-page-10{
  .title--margin {
      margin-right: 45%;
  }
  .subtitle--margin {
      margin-right: 50%;
  }
  .chart-image {
      position: relative;
      width: 59%;
  }
  .chart-note--size {
      margin: 0 0 6rem 2%;
      width: 30%;
  }
  .chart-info--margin {
      margin-bottom: 1rem;
      @media (min-width: 1366px) {
        padding-top: 1rem;
      }
  }
  .tab {
      background-color: #fff;
      border: 1px solid #f1f1f1;
      border-radius: 0.5rem;
      box-shadow: 0.5rem 0.4rem 0.5rem #f1f1f1;
      display: flex;
      justify-content: center;
      margin-bottom: 2rem;
      width: 80%;
  }
  .tab__btn {
      background: none;
      border: none;
      border-bottom: 0.4rem solid transparent;
      cursor: pointer;
      flex: 1;
      font-family: Proxima Nova;
      font-size: 1.1rem;
      font-weight: 300;
      outline: none;
      padding: 2%;
      text-transform: uppercase;
      transition: font-weight 0.4s, border-bottom-color 0.4s;
  }
  @media (min-width: 1366px) {
      .tab__btn {
          font-size: 1.3rem;
      }
  }
  .tab__btn--active {
      font-weight: 700;
  }
  .tab__btn--active.text--teal {
      border-bottom-color: #41748d;
  }
  .tab__btn--active.text--steel {
      border-bottom-color: #7ebcc6;
  }
  .tab-image {
      opacity: 0;
      position: absolute;
      transition: opacity 0.4s;
  }
  .tab-image--active {
      opacity: 1;
  }
  .cm,
  .em {
      transition: opacity 0.1s;
  }
  .em {
      position: absolute;
  }
  .transparent {
      opacity: 0;
  }
  .chart-info{
    display: none;
    &.chart-info-visible{
      display: block;
    }
  }
}