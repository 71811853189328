.react-page-15{
  .graph-bar-1{
    --bar-height: 82%; --bar-duration: 1.5s; --bar-initial:79%;
  }
  .graph-bar-2{
    --bar-height: 83%; --bar-duration: 1.5s; --bar-initial:79%;
  }
  .graph-bar-3{
    --bar-height: 79%;
  }
  .graph-bar-4{
    --bar-height: 43%; --bar-duration: 1.5s; --bar-initial:59%;
  }
  .graph-bar-5{
    --bar-height: 48%; --bar-duration: 1.5s; --bar-initial:59%;
  }
  .graph-bar-6{
    --bar-height: 59%;
  }
  .graph-bar-7{
    --bar-height: 43%; --bar-duration: 1.5s; --bar-initial:56%;
  }
  .graph-bar-8{
    --bar-height: 42%; --bar-duration: 1.5s; --bar-initial:56%;
  }
  .graph-bar-9{
    --bar-height: 56%;
  }

  .graph {
      position: relative;
  }
  .graph-container {
      display: flex;
      height: 100%;
      justify-content: space-between;
      left: 0;
      padding: 0 45% 15% 20%;
      position: absolute;
      top: 10px;
      width: 100%;
  }
  @media (min-width: 1366px) {
      .graph-container {
          top: 13px;
      }
  }
  .graph-container.inverted {
      align-items: flex-end;
      left: 0;
      top: 8px;
  }
  @media (min-width: 1366px) {
      .graph-container.inverted {
          top: 10px;
      }
  }
  .graph-bar {
      animation-duration: var(--bar-duration);
      animation-name: grow;
      animation-timing-function: var(--bar-function);
      border-radius: 0 0 8px 8px;
      color: #fff;
      font-weight: 600;
      height: var(--bar-height);
      padding: 7px 0 0;
      text-align: center;
      width: 45px;
  }
  @media (min-width: 1366px) {
      .graph-bar {
          width: 60px;
      }
  }
  .graph-bar.inverted {
      border-radius: 8px 8px 0 0;
  }
  .graph-bar > div {
      margin: 5px 0;
  }
  @keyframes grow {
      0% {
          height: var(--bar-initial, var(--bar-height));
      }
      to {
          height: var(--bar-height);
      }
  }
  .title--margin {
      margin-right: 18%;
  }
  @media (min-width: 2360px) {
      .title--margin {
          margin-right: 23%;
      }
  }
  @media (min-width: 2732px) {
      .title--margin {
          margin-right: 18%;
      }
  }
  .subtitle--margin {
      margin-bottom: 3%;
      margin-right: 40%;
  }
  .chart-image {
      width: auto;
  }
  .chart-info--margin {
      margin-top: 1%;
  }
  .chart-note--size {
      margin-right: 5%;
      padding-bottom: 2%;
      width: 27%;
  }
  @media (min-width: 1366px) {
      .chart-note--size {
          width: 25%;
      }
  }
  .chart-note__text {
      width: 70%;
  }
  .graph {
      width: 68%;
  }
  .graph-container {
    padding: 4% 10% 7.6% 17%;
  }
  .graph-container.inverted {
      top: 9px;
  }
  @media (min-width: 1366px) {
      .graph-container.inverted {
          top: 12px;
      }
  }
  .graph-bar {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 40px;
  }
  .graph-bar .bottom-index {
      font-size: 1rem;
      font-weight: 100;
      margin: 2px 0;
  }
  @media (min-width: 1366px) {
      .graph-bar {
          width: 50px;
      }
  }
  .bar-spacer {
      width: 20px;
  }

}