.react-page-5{
  .title--margin {
      margin-right: 10%;
  }
  .grid {
      margin-bottom: 8%;
  }
  .grid__column--margin {
      margin: 0 1.5%;
  }
  .text--dark-gray {
      color: #858585;
  }
  @media (min-width: 1366px) {
      .card__icon-container {
          height: 5rem;
      }
      .card__icon {
          width: 5rem;
      }
      .card__title {
          font-size: 1.3rem;
      }
  }
  .flip__btn {
      height: 2.5rem;
      width: 4.8rem;
      padding: 0;
  }
  @media (min-width: 1366px) {
      .flip__btn {
          height: 3rem;
          width: 5.4rem;
      }
  }

}