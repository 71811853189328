.App, #root{
    height: 100%;
}

main{
    height: 100%;
    @media (min-width: 1366px) {overflow: auto;}
}
.footnote-list{
    @media (min-width: 1366px) {padding-bottom: 3rem}
}

.navbar-controls-container{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.button-navbar-movement{
    border: none;
    background: none;
    padding: .4rem;
    cursor: pointer;
    height: 100%;
    z-index: 11;
    svg{
        width: 15px;
        height: 100%;
    }
    &.movement-right{
        @media (min-width: 1366px) {
            margin-left: 20px;
            margin-right: 20px;
        }
    }
}

.review-inner-container{
    line-height: 0.1;
    letter-spacing: -4px;
    font-size: 4rem;
    color: #41748d;
    height: 100%;
    margin-left: 1rem;
}


.shape-container {
    height: 100vh;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: -1;
}
.shape {
    border-radius: 3rem;
    position: absolute;
    transform: rotate(45deg);
}
.shape__darker {
    bottom: 30%;
    height: 95vh;
    opacity: 0.65;
    right: 25%;
    transition: right 1s ease-in-out;
    width: 110vw;
}
.shape__darker--moved {
    right: 2%;
}
.shape__dark {
    bottom: 15%;
    height: 110vh;
    opacity: 0.5;
    right: 84%;
    transition: right 1.2s ease-in-out;
    width: 120vw;
}
.shape__dark--moved {
    right: 20%;
}
.shape__light {
    bottom: 31%;
    height: 110vh;
    opacity: 0.2;
    right: 105%;
    transition: right 1.5s ease-in-out;
    width: 110vw;
}
.shape__light--moved {
    right: 43%;
}

.active-navigation {
    &.navbar-item:not(.safety-modal .navbar-item) {
        color: #c02c57;
    }
    &.navbar-item:not(.safety-modal .navbar-item):after {
        background-color: #c02c57;
        bottom: 0;
        content: "";
        height: 0.4rem;
        left: -1px;
        position: absolute;
        width: calc(100% + 2px);
        z-index: 1;
    }
}

.navbar-item:not(.safety-modal .navbar-item):after {
    background-color: #41748d;
    bottom: 0;
    content: "";
    height: 0.4rem;
    left: -1px;
    position: absolute;
    width: calc(100% + 2px);
    z-index: 1;
}

.sitemap-icon-img{
    width: 45px;
}

.item-red{
    color: #c02c57;
}
.logo {
    bottom: 0;
    cursor: pointer;
    position: absolute;
    right: 0;
    width: 15%;
    @media (min-width: 1366px) {
        bottom: 1rem;
        cursor: pointer;
        position: fixed;
        right: 1rem;
        width: 200px;
    }
}

.chart-container--center,
.chart-note {
    align-items: center;
    justify-content: center;
}
.chart-note {
    background-image:url(images/chart-note-bg.png);
    background-position: top;
    background-size: cover;
    border-radius: 1.5rem;
    box-shadow: 0.3rem 0.3rem 0.7rem #a2aaad;
    display: flex;
    padding: 8% 0 5%;
    position: relative;
}
@media (min-width: 1366px) {
    .chart-note {
        border-radius: 2rem;
    }
}
.chart-note__icon {
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    width: 6.3rem;
}
.chart-note__text {
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    width: 75%;
}
@media (min-width: 1366px) {
    .chart-note__text {
        font-size: 1.45rem;
    }
}
.chart-note__text sup {
    font-size: 0.65rem;
}
@media (min-width: 1366px) {
    .chart-note__text sup {
        font-size: 0.75rem;
    }
}

.back-btn {
    background: none;
    border: none;
    outline: none;
    position: absolute;
    right: 0;
    top: 0.5rem;
    width: 8%;
    cursor: pointer;
    &.align-bottom{
        bottom: .5rem;
        top: auto;
    }
}
.back-btn > img {
}
@media (min-width: 1366px) {
    .back-btn {
        right: 0;
    }
}
.reference-list-full{
    padding: 0 2.6rem;
    li{
        font-size: 0.72rem;
        margin-bottom: 0.7rem;
        padding-left: 0.35rem;
        @media (min-width: 1366px) {
            font-size: 0.88rem;
            margin-bottom: 0.8rem;
        }
    }
}

.modal .ref-list {
    margin-left: 2.6rem;
}
.modal .ref-list__item {
    font-size: 0.72rem;
    margin-bottom: 0.7rem;
    padding-left: 0.35rem;
}
@media (min-width: 1366px) {
    .modal .ref-list__item {
        font-size: 0.88rem;
        margin-bottom: 0.8rem;
    }
}
.modal .ref-list__item sup {
    font-size: 0.45rem;
}
@media (min-width: 1366px) {
    .modal .ref-list__item sup {
        font-size: 0.6rem;
    }
}

.review-buttons-container {
    position: fixed;
    opacity: 0;
    transition: all .5s ease-in-out;
    top: 7%;
    left: 1.4%;
    &.visible{
        opacity: 1;
    }
}

.review-button{
    display: block;
    height: 35px;
}

.radio-button-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio-button-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
    &.blue{
        background-color: #2196F3;
    }
    &.red{
        background-color: #c02c57;
    }
}

/* On mouse-over, add a grey background color */
.radio-button-container:hover input ~ .checkmark {
  // background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radio-button-container input:checked ~ .checkmark {
  // background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-button-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-button-container .checkmark:after {
 	top: 9px;
	left: 9px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}

.ingress{
    font-size: 110%;
}

.ingress-container{
    // padding-bottom: 3rem;
    width: 1140px;
    max-width: 100%;
    p{
        margin-bottom: 1rem;
    }
}

.timer-app{
    display: none;
}

main {
    animation: fadeIn 1.5s forwards;
    flex: 1;
    opacity: 0;
    padding: 6rem 2rem 2rem 4rem;
}

.safety-table-container{
    width: 60%;
    max-width: 100%;
    padding: .5rem 1rem 1rem;
    background-color: #fff;
    @media (min-width: 1366px) {
        width:50%;
    }
}

.safety-table-2 {
    border-bottom: 1px solid #41748d;
    border-collapse: collapse;
    border-top: 1px solid #7ebcc6;
    font-size: 0.75rem;
    margin-top: 3%;
    max-width: 100%;
    width: 100%;
}
@media (min-width: 1366px) {
    .safety-table-2 {
        font-size: 0.9rem;
    }
}
.safety-table-2 sup {
    font-size: 0.5rem;
}
@media (min-width: 1366px) {
    .safety-table-2 sup {
        font-size: 0.7rem;
    }
}
.safety-table-2 td {
    padding: .5rem 0;
}
.safety-table-2 th {
    border-bottom: 1px solid #7ebcc6;
    font-weight: 700;
    text-align: left;
}
.safety-table-2 th.border-none {
    border-bottom: none;
}
.safety-table-2 tr:first-child th {
    padding: 1% 0;
}
.safety-table-2 tr:nth-child(2) th {
    padding: 3% 0;
}

.iv__img-2 {
    bottom: 50%;
    position: absolute;
    width: 60%;
    z-index: -1;
    left: 25%;
    @media (min-width: 1366px) {
        width: 825px;
    }
}
.log-in-container{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
    .log-in-badge{
        background: #fff;
        padding: 2rem;
    }
    .log-in-input{
        border: 1px solid;
        padding: 0.5rem;
        margin-top: 2rem;
        width: 100%;
    }
    .log-in-button{
        cursor: pointer;
        font-family: Proxima Nova;
        font-weight: 600 !important;
        color: #41748d;
        text-transform: uppercase;
        padding: 0.5rem 1rem;
        font-size: 1rem;
        line-height: 1.5;
        margin-top: 1rem;
        border-radius: 10px 0 10px;
        border:2px solid #41748d;
        text-align: center;
        background: #fff;
        width: 100%;
    }
}


.black-text{
    font-size: 1.2rem;
    color: #000!important;
    font-weight: bold;

}

.fimea-header{
    font-size: 1.2rem;
    margin-bottom: 1rem;
    font-weight: bold;
}
.fimea-text{
    font-size: 1.1rem;
}

.iv-summary {
    margin-bottom: 4%;
    margin-top: -20%;
    position: relative;
}

.card__icon-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: center;
}
@media (min-width: 1366px) {
    .card__icon-container {
    }
}

.navbar-item {
    border-right: 1px solid #ced7dc;
    cursor: pointer;
    display: flex;
    height: 100%;
    position: relative;
    background: #fff;
    padding-bottom: 6px;
}

.navbar-item-text {
    align-items: center;
    display: flex;
    font-size: 0.9rem;
    height: 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
}

.navbar-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    height: 100%;
}

.safety-list__item {
    font-size: 0.9rem;
    margin-bottom: .5rem;
}
@media (min-width: 1366px) {
    .safety-list__item {
        font-size: 1.19rem;
    }
}
.safety-list__item sup {
    font-size: 0.5rem;
}
@media (min-width: 1366px) {
    .safety-list__item sup {
        font-size: 0.7rem;
    }
}