.react-page-12{
  .title--margin {
      margin-right: 18%;
  }
  .subtitle--margin {
      margin: 1% 0;
  }
  .chart-container {
      margin-bottom: 2%;
  }
  .chart-image {
      width: 73%;
  }
  .chart-info {
      margin-top: -5%;
  }
  .chart-content,
  .chart-info {
      margin-bottom: 2%;
  }
}