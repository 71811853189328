.react-page-6{

  .graph-bar-1{
    --bar-height: 77%;
    --bar-duration: 1.5s;
    --bar-initial: 56%;
  }
  .graph-bar-2{
    --bar-height: 82%;
    --bar-duration: 1.5s;
    --bar-initial: 56%;
  }
  .graph-bar-3{
    --bar-height: 56%;
  }
  .graph {
      position: relative;
  }

  .graph-container {
      display: flex;
      height: 100%;
      justify-content: space-between;
      left: 0;
      padding: 0 45% 15% 20%;
      position: absolute;
      top: 10px;
      width: 100%;
  }
  @media (min-width: 1366px) {
      .graph-container {
          top: 13px;
      }
  }
  .graph-container.inverted {
      align-items: flex-end;
      left: 0;
      top: 8px;
  }
  @media (min-width: 1366px) {
      .graph-container.inverted {
          top: 10px;
      }
  }
  .graph-bar {
      animation-duration: var(--bar-duration);
      animation-name: grow;
      animation-timing-function: var(--bar-function);
      border-radius: 0 0 8px 8px;
      color: #fff;
      font-weight: 600;
      height: var(--bar-height);
      padding: 7px 0 0;
      text-align: center;
      width: 45px;
  }
  @media (min-width: 1366px) {
      .graph-bar {
          width: 60px;
      }
  }
  .graph-bar.inverted {
      border-radius: 8px 8px 0 0;
  }
  .graph-bar > div {
      margin: 5px 0;
  }
  @keyframes grow {
      0% {
          height: var(--bar-initial, var(--bar-height));
      }
      to {
          height: var(--bar-height);
      }
  }
  .title--margin {
      margin-right: 17%;
  }
  .chart-image {
      width: auto;
  }
  .chart-note--size {
      margin-left: 8%;
      margin-top: 5%;
      width: 30%;
  }
  .chart-content {
      margin-bottom: 10px;
  }
  .chart-info--margin {
      margin-top: 3%;
  }
  .graph {
      width: 50%;
     @media (min-width: 1366px) {
       width: 37%;
     }
  }
  .graph-container {
      padding: 0 45% 8% 20%;
  }


}