.react-page-2{
    @keyframes hideMedicaments {
        0% {
            opacity: 1;
            width: unset;
        }
        99% {
            opacity: 0;
            width: unset;
        }
        to {
            width: 0;
        }
    }
    @keyframes showMedicaments {
        0% {
            opacity: 0;
            width: 0;
        }
        1% {
            opacity: 0;
            width: unset;
        }
        to {
            opacity: 1;
            width: unset;
        }
    }
    @keyframes extendCard {
        0% {
            width: var(--card-width-shrinked);
        }
        to {
            width: var(--card-width-extended);
        }
    }
    @keyframes shrinkCard {
        0% {
            width: var(--card-width-extended);
        }
        to {
            width: var(--card-width-shrinked);
        }
    }
    .title--margin {
        margin-right: 22%;
    }
    .calendar-container {
        display: flex;
        justify-content: center;
        // margin-bottom: 12%;
    }
    .text--dark-gray {
        color: #858585;
    }
    .card {
        height: 28rem;
        width: 36rem;
    }
    @media (min-width: 1366px) {
        .card {
            height: 36rem;
            width: 44rem;
        }
    }
    .card__face {
        padding: 10% 5% 5%;
    }
    .card__back {
        padding: 5rem 2rem 2rem;
    }
    @media (min-width: 1366px) {
        .card__back {
            padding: 6rem 3rem 3rem;
        }
    }
    .card-container {
        margin-right: 8%;
    }
    .card__header {
        border-top-left-radius: 0.8rem;
        border-top-right-radius: 0.8rem;
        font-size: 1.2rem;
        font-weight: 700;
        height: 3.75rem;
        left: 0;
        padding: 1%;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
    }
    .card__header sup {
        font-size: 0.8rem;
    }
    @media (min-width: 1366px) {
        .card__header {
            font-size: 1.45rem;
            height: 5rem;
        }
        .card__header sup {
            font-size: 1rem;
        }
    }
    .card__header-state1 {
        transition: opacity 0.8s;
    }
    .card__header-state2 {
        text-transform: uppercase;
        transition: opacity 0.8s;
    }
    .card__header-state2,
    .card__header-state3 {
        width: 100%;
    }
    .card__calendar,
    .card__header-state2,
    .card__header-state3 {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .card__calendar {
        transition: opacity 0.8s;
        width: 20%;
    }
    .card__calendar-container {
        display: flex;
        position: relative;
        width: 100%;
    }
    .card--extended {
        --card-width-shrinked: 36rem;
        --card-width-extended: 52.75rem;
        animation: extendCard 0.8s forwards;
    }
    @media (min-width: 1366px) {
        .card--extended {
            --card-width-shrinked: 44rem;
            --card-width-extended: 70.35rem;
        }
    }
    .card--shrinked {
        --card-width-shrinked: 36rem;
        --card-width-extended: 51.75rem;
        animation: shrinkCard 0.8s forwards;
    }
    @media (min-width: 1366px) {
        .card--shrinked {
            --card-width-shrinked: 44rem;
            --card-width-extended: 69rem;
        }
    }
    .card-list {
        list-style-type: disc;
        opacity: 1;
        transition: opacity 0.8s;
        width: 21.5rem;
    }
    .card-list sup {
        font-size: 0.7rem;
    }
    @media (min-width: 1366px) {
        .card-list {
            font-size: 1.2rem;
            width: 25.5rem;
        }
        .card-list sup {
            font-size: 0.9rem;
        }
    }
    .card-list__item {
        margin-bottom: 4%;
    }
    .card-ribbon {
        width: 34rem;
    }
    .card-ribbon-container {
        bottom: 0.5rem;
        opacity: 1;
        position: absolute;
        right: 1rem;
        transition: opacity 1s, visibility 1s;
    }
    @media (min-width: 1366px) {
        .card-ribbon {
            width: 48rem;
        }
    }
    .calendar-description {
        display: flex;
        flex: 1;
        flex-direction: column;
        font-weight: 600;
        position: relative;
        transition: opacity 1s;
    }
    .calendar-description__title {
        font-size: 1.3rem;
    }
    @media (min-width: 1366px) {
        .calendar-description__title {
            font-size: 1.45rem;
        }
    }
    .calendar-description__amount {
        align-items: flex-end;
        display: flex;
        margin-bottom: 0.2rem;
        margin-top: 1rem;
    }
    .calendar-description__number span {
        font-size: 4rem;
        font-weight: 700;
        line-height: 1.1rem;
    }
    @media (min-width: 1366px) {
        .calendar-description__number span {
            font-size: 4.4rem;
            line-height: 1.3rem;
        }
    }
    .calendar-description__first-column {
        width: 7.5rem;
    }
    @media (min-width: 1366px) {
        .calendar-description__first-column {
            width: 8.2rem;
        }
    }
    .calendar-description__per-month {
        flex: 1;
        font-size: 1.1rem;
    }
    @media (min-width: 1366px) {
        .calendar-description__per-month {
            font-size: 1.3rem;
        }
    }
    .calendar-description__how-long {
        display: flex;
    }
    .calendar-description__time {
        flex: 1;
        font-size: 0.8rem;
        font-weight: 500;
    }
    @media (min-width: 1366px) {
        .calendar-description__time {
            font-size: 1rem;
        }
    }
    .chart-info--size {
        font-size: 0.6rem;
        font-weight: 300;
    }
    @media (min-width: 1366px) {
        .chart-info--size {
            font-size: 0.8rem;
        }
    }
    .flip__btn--padding {
        padding: 0.5rem 1.2rem 0.5rem 2rem;
    }
    @media (min-width: 1366px) {
        .flip__btn--padding {
            padding: 0.6rem 1.3rem 0.6rem 2.1rem;
        }
    }
    .flip__btn {
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.8s;
        visibility: hidden;
    }
    .flip__btn--back {
        z-index: 1;
    }
    .flip__btn--visible {
        opacity: 1;
        pointer-events: all;
        visibility: visible;
    }
    .medicaments {
        // margin-bottom: -41%;
        width: 70%;
        &.hidden{
            width: 0;
        }
    }
    @media (min-width: 1366px) {
        .medicaments {
            // margin-bottom: -36%;
            width: 60%;
        }
    }
    .medicaments-container {
        align-items: center;
        display: flex;
        justify-content: center;
    }
    .medicaments--hidden {
        animation: hideMedicaments 0.5s forwards;
    }
    .medicaments--shown {
        animation: showMedicaments 0.5s forwards;
    }
    .slider {
        -webkit-appearance: none;
        background: #fff;
        border: 1px solid #f1f1f1;
        border-radius: 10rem;
        box-shadow: inset 0.08rem 0.08rem 0.2rem #d9d9d9;
        display: block;
        height: 0.7rem;
        margin: 4% 0 2%;
        outline: none;
    }
    .slider,
    .slider-container {
        width: 100%;
    }
    @media (min-width: 1366px) {
        .slider {
            height: 0.9rem;
        }
    }
    .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        background-color: #fff;
        border: 1px solid #f1f1f1;
        border-radius: 50%;
        box-shadow: 0.08rem 0.08rem 0.5rem #f1f1f1;
        cursor: pointer;
        height: 1.5rem;
        transition: background-color 0.3s;
        width: 1.5rem;
        z-index: 2;
    }
    @media (min-width: 1366px) {
        .slider::-webkit-slider-thumb {
            height: 1.6rem;
            width: 1.6rem;
        }
    }
    .slider-steel::-webkit-slider-thumb {
        background-color: #7ebcc6;
    }
    .slider-coral::-webkit-slider-thumb {
        background-color: #f16464;
    }
    .slider-red::-webkit-slider-thumb {
        background-color: #c02c57;
    }
    .slider-text {
        color: #41748d;
        font-weight: 600;
        margin-left: 2%;
        opacity: 0.5;
        transition: opacity 0.8s;
    }
    @media (min-width: 1366px) {
        .slider-text {
            font-size: 1.2rem;
        }
    }
    .slider-text--visible {
        opacity: 1;
    }
    .slider__numbers {
        display: flex;
        justify-content: space-between;
        padding: 0 0.5rem;
        text-align: center;
        width: 100%;
    }
    .slider__number {
        font-weight: 600;
        opacity: 0.5;
        transition: color 0.8s, opacity 0.8s;
    }
    @media (min-width: 1366px) {
        .slider__number {
            font-size: 1.2rem;
        }
    }
    .slider__number--visible {
        opacity: 1;
    }
    .hidden {
        opacity: 0;
        visibility: hidden;
    }

}