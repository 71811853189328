.react-page-16{
  .navbar {
      background-color: #fff;
      color: #666;
      font-family: Proxima Nova;
      font-weight: 400;
      height: 5%;
      width: 100%;
  }
  .navbar .underline {
      background-color: #41748d;
      height: 0.4rem;
      width: 100%;
  }
  @media (min-width: 1366px) {
      .navbar .underline {
          height: 0.5rem;
      }
  }
  .navbar-wrapper {
      display: grid;
      grid-template-columns: 0.8fr 1fr 1fr 1fr 1fr 1fr;
      height: 100%;
  }
  .navbar-item {
      border-right: 1px solid #ced7dc;
      cursor: pointer;
      display: flex;
      height: 100%;
      position: relative;
  }
  .navbar-item-text {
      align-items: center;
      display: flex;
      font-size: 0.9rem;
      height: 100%;
      justify-content: center;
      text-align: center;
      width: 100%;
  }
  @media (min-width: 1366px) {
      .navbar-item-text {
          font-size: 1.2rem;
      }
  }
  .title--margin {
      margin-right: 29%;
  }
  .subtitle--margin {
      margin-right: 43%;
  }
  .chart-container {
      margin-bottom: 6%;
  }
  .chart-image {
      width: 61%;
  }
  .chart-info--margin {
      margin-bottom: 3%;
      margin-top: -3%;
  }
  .chart-note--size {
      margin-left: -1%;
      padding-bottom: 2%;
      width: 30%;
  }
  @media (min-width: 1366px) {
      .chart-note--size {
          width: 29%;
      }
  }
  .list--size {
      margin-right: 42%;
  }

}