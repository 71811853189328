.react-page-13{
  .line-1{
    --line-destination: 60%; --line-duration:2s
  }
  .line-2{
    --line-destination: 65%; --line-duration:2.5s
  }

  .title--margin {
      margin-right: 22%;
  }
  .subtitle--margin {
      margin: 1% 0;
  }
  .chart-image {
      width: auto;
  }
  .chart-container {
      justify-content: flex-start;
  }
  .chart-info--size {
      margin-top: 3%;
  }
  .list--size {
      margin-right: 38%;
  }
  .chart-frame {
      position: relative;
      width: 85%;
  }
  .chart-frame .line {
      animation-duration: var(--line-duration);
      animation-name: fadeInNdrop;
      height: auto;
      left: 0;
      max-width: 100%;
      padding: 6% 26% 0 14%;
      position: absolute;
      top: var(--line-destination);
  }
  @keyframes fadeInNdrop {
      0% {
          opacity: 0;
          top: 0;
      }
      to {
          opacity: 1;
          top: var(--line-destination);
      }
  }

}