.react-page-18{
    .grid{
        margin: 0 2rem 2rem 0;
        @media (min-width: 1366px) {
            margin: 0 4rem 4rem 0;
        }
    }

    .grid__column--margin{
        margin: 1rem;
    }
    .iv-card{
        width: 100%;
        height: 100%;
        background: rgb(126 188 198 / 30%);
        border-radius: 0.8rem;
        position: relative;
        padding: 0 1rem 2rem;
        min-height: 26rem;
        @media (min-width: 1366px) {
            min-height: 24rem;
        }
    }
    .iv-card__arrow{
        padding: 1rem;
        margin: 0 auto;
    }
    .iv-card__ball{
        border-radius: 150px;
        background: #41748d;
        // padding: 2rem;
        margin: 2rem auto 0;
        color: white;
        height: 150px;
        align-items: center;
        width: 150px;
        position: relative;
        .ball__text{
            left: 11%;
            bottom: 33%;
            width: 116px;
            position: absolute;
            text-transform: uppercase;
            font-weight: bold;
            text-align: center;
            font-family: Proxima Nova;
        }
    }
    .large-text{
        font-size: 160%;
    }
    .arrow-container{
        text-align: center;
        padding: 0.5rem 0 0;
        position: relative;
    }
    img{
        margin: 0 auto;
    }
    .iv-card__bottom{
        margin: 0 auto 2px;
        text-align: center;
        padding: 1rem;
        background: #7ebcc6;
        border-radius: 10px 10px 0 0;
        color: #fff;
        font-weight: bold;
        width: 90%;
        height: 24%;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);

        @media (min-width: 1366px) {
            width: 70%;
            height: 20%;
        }
    }
    .iv-card__text{
        padding-top: 1rem;
        text-align: center;
    }

    .title--margin {
        margin-right: 18%;
    }
    .list__title {
        font-weight: 600;
        margin: 2% 0;
    }
    @media (min-width: 1366px) {
        .list__title {
            font-size: 1.2rem;
        }
    }
    .list__title sup {
        font-size: 0.6rem;
    }
    @media (min-width: 1366px) {
        .list__title sup {
            font-size: 0.8rem;
        }
    }
    .list--size {
        margin-left: 2%;
        margin-right: 53%;
    }
    .iv {
        margin: 4% 0;
        position: relative;
    }
    .iv__gifs {
        width: 30%;
    }
    .iv__img {
        bottom: 50%;
        position: absolute;
        width: 60%;
        z-index: -1;
    }
}