.react-page-1{
    .title--margin {
        margin-bottom: 2rem;
    }
    .grid {
        margin-bottom: 8%;
    }
    .grid__column--margin {
        margin: 0 1.5%;
    }
    .text--dark-gray {
        color: #858585;
    }
    .card__icon--size {
        width: 4.2rem;
    }
    @media (min-width: 1366px) {
        .card__icon--size {
            // width: 5.2rem;
        }
    }
    .card__face--static {
        align-items: center;
        background-color: #fff;
        border-radius: 0.8rem;
        box-shadow: 0.3rem 0.3rem 0.7rem #a2aaad;
        display: flex;
        flex-direction: column;
        // height: 23.5rem;
        // margin-bottom: 22%;
        padding: 2rem 2rem 2rem;
        min-height: 24rem;
        position: relative;
        width: 100%;
    }
    @media (min-width: 1366px) {
        .card__face--static {
            // height: 30.5rem;
        }
    }
    .card__header {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 8rem;
        justify-content: flex-end;
        margin-bottom: 2rem;
    }
    .card__description {
        flex: 1;
        font-size: 0.9rem;
        font-weight: 400;
        // padding-right: 12%;
    }
    @media (min-width: 1366px) {
        .card__description {
            font-size: 1.1rem;
        }
    }
    .card__description sup {
        font-size: 0.6rem;
    }
    @media (min-width: 1366px) {
        .card__description sup {
            font-size: 0.75rem;
        }
    }
    .card__title--margin {
        margin-top: 2rem;
    }
    .flip__btn--padding {
        padding: 3% 10%;
    }
    .flip__icon--size {
        width: 1.4rem;
    }
    @media (min-width: 1366px) {
        .flip__icon--size {
            width: 1.8rem;
        }
    }

    .icon-fast{
        margin-bottom: 6px;
    }
}